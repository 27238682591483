<template>
  <v-app>
    <div>
       <v-alert
          v-model="alert"
          border="left"
          dark
          dismissible
          class="alert"
          :class="{'alert-error':alertType=='error', 'alert-success':alertType=='success'}"
        >
        {{alertMessage}}
        </v-alert>
    </div>
    <div class="row">
      <div class="col-md-6 ml-auto mr-auto">
        <div class="mb-8 mt-8 text-center">
          <h1>Configure Allowances</h1>
        </div>
        <div>
          <!-- <SelectField
            :items="jobTitles"
            v-model="formData.jobTitle"
            :label="'Job Titles'"
            :itemText="'name'"
            :itemValue ="'id'"
          /> -->
          <SelectField
            :items="unionMembers"
            v-model="formData.unionMember"
            :label="'Representation Status'"
            :itemText="'name'"
            :itemValue ="'id'"
          />
        </div>
        <div>
          <v-simple-table>
            <template>
              <thead>
                <tr>
                  <th class="text-left">
                    Salary component
                  </th>
                  <th class="text-left">
                    Frequency
                  </th>
                  <th class="text-left">
                    Amount / Rate
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in salaryComponents" :key="item.id">
                  <td>
                    {{item.description}}
                  </td>
                  <td>
                    {{item.frequency}}
                  </td>
                  <td>
                    <v-text-field
                    class="mt-4"
                    v-model="formData.values[index]"
                    type="number"
                    value="0"
                      outlined
                      hide-details
                      @blur="valueInputed(item,formData.values[index])"
                      dense
                    />
                    <p class="hint">{{item.amountLabel}}</p>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-row class="mt-5">
            <v-col class="d-flex justify-end">
              <Button :btnType="'Submit'" :label="'Submit'"  :isLoading="isLoading" @onClick="dialog = true" class="mr-2"/>
                  <Button :btnType="'Cancel'" :label="'Reset'" @onClick="reset" />
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
    <ConfirmationDialog :title="'Are you sure you want to submit?'" :dialog="dialog" :btnTitle="'Yes'" @close="dialog = false" @btnAction="save"/>
  </v-app>
</template>
<script>
import SelectField from '@/components/ui/form/SelectField.vue'
import Button from '@/components/ui/form/Button.vue'
import ConfirmationDialog from '@/components/ui/confirmationDialog.vue'
import {
  jobTitleService,
  unionMemberService,
  salaryComponentService
} from '@/services'
export default {
  components: {
    SelectField,
    Button,
    ConfirmationDialog
  },
  data () {
    return {
      isLoading: false,
      dialog: false,
      formData: {
        jobTitle: '',
        unionMember: '',
        salaryComponent1: '',
        value1: '',
        salaryComponent2: '',
        value2: '',
        salaryComponent3: '',
        value3: '',
        values: []

      },
      alertMessage: '',
      alert: false,
      alertType: '',
      jobTitles: [],
      unionMembers: [],
      salaryComponents: [],
      selected: []
    }
  },
  methods: {
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
    },
    loadUnionMembers () {
      unionMemberService.getAllUnionMembers().then(result => {
        this.unionMembers = result.data
      }).catch(error => {
        console.log(error)
      })
    },
    valueInputed (item, value) {
      let valueData = value
      if (!value) {
        valueData = '0'
      }
      let index = null
      for (let i = 0; i < this.selected.length; i++) {
        if (this.selected[i].id === item.id) {
          index = i
          break
        }
      }
      if (index !== null) {
        this.selected.splice(index, 1)
      }
      this.selected.push({ id: item.id, value: valueData })
    },
    save () {
      const data = [ ]
      this.selected.forEach(sel => {
        data.push(
          {
            'UnionMembershipId': this.formData.unionMember,
            'SalaryComponentId': sel.id,
            'Amount': sel.value
          }
        )
      })
      this.isLoading = true
      unionMemberService.createUnionAllowance(data).then(result => {
        this.showAlertMessage('Union Rate created ', 'success')
      }).catch((error) => {
        console.log(error)
        this.showAlertMessage('Union Rate failed', 'error')
      }).finally(() => {
        this.isLoading = false
        this.dialog = false
        this.reset()
      })
    },
    reset () {
      this.selected = []
      this.formData.values = []
    }
  },
  mounted () {
    this.loadUnionMembers()
    jobTitleService
      .getAllJobTitles()
      .then((result) => {
        console.log(result.data.items)
        this.jobTitles = result.data.items
      })
      .catch((error) => {
        console.log('jobtitleservice', error)
      })
    salaryComponentService.getAllUnionAllowance().then((result) => {
      this.salaryComponents = result.data
    }).catch(error => {
      console.log(error)
    })
  }
}
</script>
<style scoped>
.alert {
  position: fixed;
  z-index: 9999;
  top: 5px;
  min-width: 50%;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.hint {
  font-size: 13px;
  margin-top: 1px;
}

</style>
